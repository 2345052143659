import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const TypesOfCampaigns = ({ location }) => {
  const title = "你能组织即时赢家以外的活动吗？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT允许你通过选择参与方式→关注和转发、标签和张贴收据；抽奖方式→手动抽奖、随机抽奖、刮奖和游戏；以及礼品→产品、数字礼品和数字优惠券来组织一个活动。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">可以组织哪些运动？</h1>
            <p className="qa-page__answer">
              可以组织 "关注和转发活动"、"标签活动 "和 "提交收据活动"。
              <br />
              可以根据目标受众和所处理的产品和服务来选择绘画方法和礼物。
            </p>
            <div className="qa-page__detail-container">
              <p className="qa-page__text">
                PARKLoT活动将遵循以下流程
                <br />
                (1) 公司官方推特账户推送竞选推文
                <br />
                (2) 用户参与活动
                <br />
                (3) 抽奖
                <br />
                (4) 赢取奖品
                <br />
              </p>
              <p className="qa-page__text">
                每个流程都有几种方法，（2）用户参与活动，（3）抽签，（4）赢得奖品，可以根据活动的目的和提供的产品和服务来选择。
              </p>
              <p className="qa-page__text">
                本节概述了这些选项中的每一项及其费用。
              </p>
              <h2 className="qa-page__h2">
                关于"（2）用户参与活动 "中的选项。
              </h2>
              <p className="qa-page__text">
                有三种方式可以参与。
                <br />
                (1) 关注和转发
                <br />
                (2) 标签
                <br />
                (3) 提交收据
                <br />
              </p>
              <h3 className="qa-page__h3">(1) 关注和转发</h3>
              <p className="qa-page__text">
                关注该公司的官方账户并转发活动推文以参与活动。
              </p>
              <p className="qa-page__text">包括在每月的订阅计划中。</p>
              <h3 className="qa-page__h3">(2) 标签</h3>
              <p className="qa-page__text">
                通过在推特上发布包含特定标签的推文来参与该活动。
              </p>
              <p className="qa-page__text">
                除了统一的月度计划外，还需要支付可选的费用。
              </p>
              <h3 className="qa-page__h3">(3) 提交收据</h3>
              <p className="qa-page__text">
                购买产品的收据通过DM发送，以参与活动。
              </p>
              <p className="qa-page__text">
                除了统一的月度计划外，还需要支付可选的费用。
              </p>
              <h2 className="qa-page__h2">关于"(3) 抽奖 "中的选项。</h2>
              <p className="qa-page__text">
                有两种抽奖方法："期后抽奖 "和 "实时抽奖"。
              </p>
              <p className="qa-page__text">
                '期后抽签'可以是'随机抽签'或'人工抽签'。
                <br />
                就 "实时彩票 "而言，有三种类型："随机抽奖"、"刮奖 "和 "游戏"。
                <br />
              </p>
              <h3 className="qa-page__h3">(1) 期末后的抽奖。</h3>
              <p className="qa-page__text">
                活动结束后，将进行集体抽奖，并通知参与者获奖结果。
              </p>
              <p className="qa-page__text">
                随机抽奖：从申请者名单中随机选出获奖者，并以人工方式向获奖者发送DM。
              </p>
              <p className="qa-page__text">
                随机抽奖：从申请者名单中随机选出获奖者，并以人工方式向获奖者发送DM。
              </p>
              <h3 className="qa-page__h3">(2) 实时抽奖</h3>
              <p className="qa-page__text">
                参加活动后，参与者会立即得到竞赛结果的通知。
              </p>
              <p className="qa-page__text">
                对于实时抽奖，有三种抽奖方法可供选择。
              </p>
              <h4 className="qa-page__h4">随机抽奖</h4>
              <p className="qa-page__text">
                PARKLoT系统根据预先设定的中奖率自动抽签，并向参与者发送包含抽签结果确认界面URL的DM。
              </p>
              <p className="qa-page__text">
                收到DM的参与者会被转到抽签结果确认界面，以检查结果。
              </p>
              <p className="qa-page__text">包括在每月的订阅计划中。</p>
              <h4 className="qa-page__h4">划伤</h4>
              <p className="qa-page__text">包含刮刮卡URL的DM被发送给参与者。</p>
              <p className="qa-page__text">
                当收到DM的参与者点击URL时，会显示一张刮奖卡，他们可以通过刮奖卡查看中奖结果。
              </p>
              <p className="qa-page__text">
                除了统一的月度计划外，还需要支付可选的费用。
              </p>
              <h4 className="qa-page__h4">游戏</h4>
              <p className="qa-page__text">
                一个包含加入游戏的URL的DM被发送给参与者。
              </p>
              <p className="qa-page__text">
                收到DM的参与者点击该网址并参与游戏。
                比赛进行完毕后，将确认比赛结果。
              </p>
              <p className="qa-page__text">
                除了统一的月度计划外，还需要支付可选的费用。
              </p>
              <h2 className="qa-page__h2">(3) 关于可作为礼物的选择。</h2>
              <p className="qa-page__text">
                这些礼物包括 "产品"、"数字礼品代码 "和 "数字代金券"。
              </p>
              <h3 className="qa-page__h3">货物</h3>
              <p className="qa-page__text">你可以介绍需要送货的产品。</p>
              <p className="qa-page__text">
                获奖的参与者在PARKLoT提供的专用页面上登记他们的地址。
              </p>
              <p className="qa-page__text">组织者将货物送到注册地址。</p>
              <p className="qa-page__text">
                这可以在一个固定的月度计划内进行。
              </p>
              <h3 className="qa-page__h3">数字礼品代码</h3>
              <p className="qa-page__text">
                可以赠送数字礼品代码，如亚马逊礼品券。
              </p>
              <p className="qa-page__text">
                在月度订阅计划中，可以通过Twitter
                DM从获奖者名单屏幕上手动发送礼品代码。
              </p>
              <p className="qa-page__text">
                自动向获奖者发送礼品代码需要在每月订阅之外支付一笔可选费用。
              </p>
              <h3 className="qa-page__h3">数字优惠券</h3>
              <p className="qa-page__text">
                你可以提出你自己的代金券，可以在实体店和网上商店使用。
              </p>
              <p className="qa-page__text">
                优惠券可以从 "取消的优惠券"、"定时的优惠券 "和
                "一段时间内的无限优惠券 "中选择。
              </p>
              <p className="qa-page__text">
                可取消的优惠券
                "使商店里的工作人员可以点击屏幕上的一个按钮来确认使用，从而防止多次使用。
                <br />
                定时优惠券
                "在从显示的时间起经过一定的时间后自动使用，在使用时不需要店员照顾优惠券。
                <br />
                在规定的时间内，"无限期优惠券 "可以任意使用，从而鼓励继续使用。
              </p>
              <p className="qa-page__text">
                这可以在一个固定的月度计划内进行。
              </p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default TypesOfCampaigns
